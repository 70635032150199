import { LinkOutlined } from "@ant-design/icons";
import { Alert, Button, Col, List, message, Row, Switch, Tag } from "antd";
import { useCallback, useEffect, useState } from "react";
import api from "../../../../../boot/api";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import {
  orgDisableGlobalIPBlockLists,
  orgEnableGlobalIPBlockLists,
  orgFetchGlobalIPBlockLists,
} from "../../../../../slices/app/org/ipblocklists";
import { addToGlobalIPBlockLists, removeFromGlobalIPBlockLists } from "../../../../../slices/app/org/mirrors";

const OrgMirrorsShowIPBlockLists = () => {
  const dispatch = useAppDispatch();
  const mirror = useAppSelector(state => state.app.org.mirrors.mirror);
  const globalIPBlockListProviders = useAppSelector(state => state.app.org.ipblocklists.globalIPBlockLists);

  const [loading, setLoading] = useState(false);
  const [ipBlockListDeploying, setIPBlockListDeploying] = useState(false);

  const fetchGlobalIPBlockLists = useCallback(async () => {
    await dispatch(orgFetchGlobalIPBlockLists());
  }, [dispatch]);

  const enableGlobalIPBlockLists = useCallback(async (blockListID?: string) => {
    if (mirror && mirror.ID) {
      setLoading(true);

      if (blockListID) {
        try {
          await dispatch(orgEnableGlobalIPBlockLists({
            mirrorID: mirror?.ID,
            body: {
              GlobalBlockListID: blockListID,
            },
          }));
          dispatch(addToGlobalIPBlockLists({ mirrorID: mirror.ID, listID: blockListID }));

          message.success(`Global blocklist ${blockListID} enabled`);
        } catch (err) {
          console.log(err);
        }
      }

      setLoading(false);
    }
  }, [dispatch, mirror]);

  const disableGlobalIPBlockLists = useCallback(async (blockListID?: string) => {
    if (mirror && mirror.ID) {
      setLoading(true);

      if (blockListID) {
        try {
          await dispatch(orgDisableGlobalIPBlockLists({
            mirrorID: mirror?.ID,
            body: {
              GlobalBlockListID: blockListID,
            },
          }));
          dispatch(removeFromGlobalIPBlockLists({ mirrorID: mirror.ID, listID: blockListID }));

          message.success(`Global blocklist ${blockListID} disabled`);
        } catch (err) {
          console.log(err);
        }
      }

      setLoading(false);
    }
  }, [dispatch, mirror]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await fetchGlobalIPBlockLists();
      setLoading(false);
    })();
    // }, [fetchGlobalIPBlockLists, mirror]);
  }, [fetchGlobalIPBlockLists]);

  const forceBlockListsDeployment = async () => {
    setIPBlockListDeploying(true);

    if (mirror) {
      console.log("DEPLOY NOW");

      try {
        await api.post(`/org/mirrors/${mirror.ID}/blocklist/force-deploy`);
        message.success("IP block list deployed");
      } catch (err: any) {
        console.log(err);

        let errMessage = "";
        if (err.toString) {
          errMessage = err.toString();
        } else {
          errMessage = err + "";
        }

        message.error(`IP block list not deployed: ${errMessage}`);
      }
    }

    setIPBlockListDeploying(false);
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Alert
            message={`
              Global block lists are automatically fetched and
              updated each hour without any action from your side.
            `}
            type="info"
            style={{ marginBottom: "1rem" }}
            showIcon
          />

          <p>IP Block list status: {mirror?.IPBlockListStatus}</p>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button
            disabled={mirror?.Status !== "RUNNING" || mirror.IPBlockListStatus !== "OK" || ipBlockListDeploying}
            loading={ipBlockListDeploying}
            onClick={forceBlockListsDeployment}
          >
            Force block lists deployment
          </Button>
          <br />
          <br />
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          {/* @TODO: list item react keys */}
          <List
            bordered
            loading={loading}
            itemLayout="horizontal"
            header={<div>Global IP block lists</div>}
            dataSource={globalIPBlockListProviders}
            renderItem={(provider) => {
              return (
                <List.Item
                  actions={[
                    <Switch
                      checked={mirror?.GlobalIPBlockLists?.includes(provider.ID || "")}
                      onClick={(newState) => {
                        if (newState) {
                          enableGlobalIPBlockLists(provider.ID);
                        } else {
                          disableGlobalIPBlockLists(provider.ID);
                        }
                      }}
                    />,
                  ]}
                >
                  <List.Item.Meta
                    title={
                      <a rel="noreferrer" target="_blank" href={provider.Source}>
                        {provider.Name} <LinkOutlined />
                      </a>
                    }
                    description={
                      <div>
                        <p>{provider.Description}</p>
                        <div>
                          {provider.Countries?.map((countryCode) => <Tag>{countryCode}</Tag>)}
                        </div>
                      </div>
                    }
                  />
                </List.Item>
              );
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default OrgMirrorsShowIPBlockLists;
