import { PlusOutlined } from "@ant-design/icons";
import { Button, Card } from "antd";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OrgMirrorsList from "../../../../components/org/mirrors/List";
import Padder from "../../../../components/utils/Padder";
import PageHeader from "../../../../components/utils/PageHeader";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { orgFetchOrgMirrors } from "../../../../slices/app/org/mirrors";
import { setBreadcrumpItems } from "../../../../slices/breadcrump";
import styles from "./Index.module.css";

const MirrorsIndex = () => {
  const dispatch = useAppDispatch();
  const mirrors = useAppSelector(state => state.app.org.mirrors.mirrors);
  const mirrorsTotalCount = useAppSelector(state => state.app.org.mirrors.mirrorsTotalCount);

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const fetchMirrors = useCallback(async () => {
    setLoading(true);
    await dispatch(orgFetchOrgMirrors({ Per: pageSize, Page: page }));
    setLoading(false);
  }, [dispatch, page, pageSize]);

  useEffect(() => {
    dispatch(setBreadcrumpItems([
      {
        label: "Mirrors",
        link: "/app/org/mirrors",
      },
    ]));

    fetchMirrors();
  }, [dispatch, fetchMirrors]);

  return (
    <Padder>
      <Card>
        <PageHeader
          title="Mirrors"
          subTitle={`Mirrors management ${(mirrors || []).length}/5`}
          actions={
            <>
              <Link to="/app/org/mirrors/new">
                <Button
                  type="primary"
                  disabled={loading || ((mirrors || []).length >= 5)}
                  size="large"
                  icon={<PlusOutlined />}
                >
                  Create mirror
                </Button>
              </Link>
            </>
          }
        />
      </Card>

      <div className={styles.content}>
        <OrgMirrorsList
          pagination={{
            total: mirrorsTotalCount,
            defaultCurrent: 1,
            current: page,
            showSizeChanger: true,
          }}
          mirrors={mirrors}
          loading={loading}
          onChange={(ev) => {
            setPageSize(ev.pageSize || 10);
            setPage(ev.current || 1);
          }}
        />
      </div>
    </Padder>
  );
};

export default MirrorsIndex;
