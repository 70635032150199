import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../boot/api";
import {
  CommonDomainDeployment,
  CommonMirror,
  DinahOrgGetMirrorOutput,
  DinahOrgListMirrorDomainsOutput,
  DinahOrgListMirrorsOutput,
  DinahOrgRenameMirrorBody,
  DinahOrgSetMirrorCountryCodesBody,
} from "../../../lib/grpcapi";

export const orgFetchOrgMirrors = createAsyncThunk(
  "org/mirrors/orgFetchOrgMirrors",
  async (params: any): Promise<DinahOrgListMirrorsOutput> => {
    const { data } = await api.get(`/org/mirrors`, { params });
    return data;
  },
);

export const orgFetchOrgMirror = createAsyncThunk(
  "org/mirrors/orgFetchOrgMirror",
  async (id: string): Promise<DinahOrgGetMirrorOutput> => {
    const { data } = await api.get(`/org/mirrors/${id}`);
    return data;
  },
);

export interface OrgFetchOrgMirrorDomainsInput {
  id: String;
  params: any;
}

export const orgFetchOrgMirrorDomains = createAsyncThunk(
  "org/mirrors/orgFetchOrgMirrorDomains",
  async (input: OrgFetchOrgMirrorDomainsInput): Promise<DinahOrgListMirrorDomainsOutput> => {
    const { data } = await api.get(`/org/mirrors/${input.id}/domains`, { params: input.params });
    return data;
  },
);

export const orgRenameMirror = createAsyncThunk(
  "org/mirrors/renameMirror",
  async (input: { mirrorID: string; name: string }) => {
    const payload: DinahOrgRenameMirrorBody = { Name: input.name };
    await api.post(`/org/mirrors/${input.mirrorID}/name`, payload);
    return input;
  },
);

export const orgSetMirrorCountryCodes = createAsyncThunk(
  "org/mirrors/orgSetMirrorCountryCodes",
  async (input: { mirrorID: string; countryCodes: string[] }): Promise<{ id: string; countryCodes: string[] }> => {
    const payload: DinahOrgSetMirrorCountryCodesBody = { CountryCodes: input.countryCodes };
    await api.post(`/org/mirrors/${input.mirrorID}/country-codes`, payload);
    return {
      id: input.mirrorID,
      countryCodes: input.countryCodes,
    };
  },
);

export interface MirrorsState {
  mirrors?: CommonMirror[];
  mirrorsTotalCount?: number;
  mirror?: CommonMirror;
  mirrorDomains?: CommonDomainDeployment[];
  mirrorDomainsTotalCount?: number;
}

const initialState: MirrorsState = {};

const organizationsSlice = createSlice({
  name: "mirrors",
  initialState,
  reducers: {
    setMirrors: (state, action: PayloadAction<CommonMirror[]>) => {
      state.mirrors = action.payload;
    },

    // Remove a global ip block list from a mirror. <mirrorID, global blocklist name>.
    removeFromGlobalIPBlockLists: (state, action: PayloadAction<{ mirrorID: string; listID: string }>) => {
      if (state.mirror && state.mirror.ID === action.payload.mirrorID) {
        state.mirror.GlobalIPBlockLists = state.mirror.GlobalIPBlockLists || [];
        let eidx = state.mirror.GlobalIPBlockLists.indexOf(action.payload.listID);
        state.mirror.GlobalIPBlockLists.splice(eidx, 1);
      }

      state.mirrors = (state.mirrors || []).map(mirror => {
        if (mirror.ID === action.payload.mirrorID) {
          mirror.GlobalIPBlockLists = mirror.GlobalIPBlockLists || [];
          let eidx = mirror.GlobalIPBlockLists.indexOf(action.payload.listID);
          mirror.GlobalIPBlockLists.splice(eidx, 1);
        }

        return mirror;
      });
    },

    // Add a global ip block list into a mirror. <mirrorID, global blocklist name>.
    addToGlobalIPBlockLists: (state, action: PayloadAction<{ mirrorID: string; listID: string }>) => {
      if (state.mirror && state.mirror.ID === action.payload.mirrorID) {
        state.mirror.GlobalIPBlockLists = state.mirror.GlobalIPBlockLists || [];
        state.mirror.GlobalIPBlockLists.push(action.payload.listID);
      }

      state.mirrors = (state.mirrors || []).map(mirror => {
        if (mirror.ID === action.payload.mirrorID) {
          mirror.GlobalIPBlockLists = mirror.GlobalIPBlockLists || [];
          mirror.GlobalIPBlockLists.push(action.payload.listID);
        }

        return mirror;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(orgFetchOrgMirrors.fulfilled, (state, action) => {
      if (action.payload) {
        state.mirrors = action.payload.Mirrors;
        state.mirrorsTotalCount = parseInt(action.payload.TotalCount || "0");
      }
    });

    builder.addCase(orgFetchOrgMirror.fulfilled, (state, action) => {
      if (action.payload) {
        state.mirror = action.payload.Mirror;
      }
    });

    builder.addCase(orgFetchOrgMirrorDomains.fulfilled, (state, action) => {
      if (action.payload) {
        state.mirrorDomains = action.payload.DomainDeployments;
        state.mirrorDomainsTotalCount = parseInt(action.payload.TotalCount || "0");
      }
    });

    builder.addCase(orgRenameMirror.fulfilled, (state, action) => {
      if (action.payload) {
        if (state.mirror) {
          if (action.payload.mirrorID === state.mirror.ID) {
            state.mirror.Name = action.payload.name;
          }
        }

        state.mirrors = (state.mirrors || []).map(m => {
          if (action.payload.mirrorID === m.ID) {
            m.Name = action.payload.name;
          }

          return m;
        });
      }
    });

    builder.addCase(orgSetMirrorCountryCodes.fulfilled, (state, action) => {
      if (action.payload) {
        if (state.mirror) {
          if (action.payload.id === state.mirror.ID) {
            state.mirror.CountryCodes = action.payload.countryCodes;
          }
        }

        state.mirrors = (state.mirrors || []).map(m => {
          if (action.payload.id === m.ID) {
            m.CountryCodes = action.payload.countryCodes;
          }

          return m;
        });
      }
    });
  },
});

export const {
  setMirrors,
  removeFromGlobalIPBlockLists,
  addToGlobalIPBlockLists,
} = organizationsSlice.actions;
export default organizationsSlice.reducer;
