import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../boot/api";
import {
  CommonGlobalIPBlockList,
  DinahOrgDisableGlobalIPBlockListsBody,
  DinahOrgEnableGlobalIPBlockListsBody,
  DinahOrgListGlobalIPBlockListsOutput,
} from "../../../lib/grpcapi";

export const orgFetchGlobalIPBlockLists = createAsyncThunk(
  "org/ipblocklists/orgFetchGlobalIPBlockLists",
  async (): Promise<DinahOrgListGlobalIPBlockListsOutput> => {
    const { data } = await api.get(`/org/global-ip-blocklists`);
    return data;
  },
);

export const orgEnableGlobalIPBlockLists = createAsyncThunk(
  "org/ipblocklists/orgEnableGlobalIPBlockLists",
  async (input: { mirrorID: string; body: DinahOrgEnableGlobalIPBlockListsBody }) => {
    await api.post(`/org/mirrors/${input.mirrorID}/blocklist/global/enable`, input.body);
  },
);

export const orgDisableGlobalIPBlockLists = createAsyncThunk(
  "org/ipblocklists/orgDisableGlobalIPBlockLists",
  async (input: { mirrorID: string; body: DinahOrgDisableGlobalIPBlockListsBody }) => {
    await api.post(`/org/mirrors/${input.mirrorID}/blocklist/global/disable`, input.body);
  },
);

export interface IPBlockListsState {
  globalIPBlockLists?: CommonGlobalIPBlockList[];
}

const initialState: IPBlockListsState = {};

const ipblocklistsSlice = createSlice({
  name: "domains",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(orgFetchGlobalIPBlockLists.fulfilled, (state, action) => {
      if (action.payload) {
        state.globalIPBlockLists = action.payload.GlobalIPBlockLists;
      }
    });
  },
});

export default ipblocklistsSlice.reducer;
